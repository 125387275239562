<template>
  <div>
    <b-card no-body class="h-100">
      <b-card-header>
        <b-card-title>Detail News Trend</b-card-title>
        <b-button
          variant="outline-primary"
          class="ml-auto mr-1"
          @click="editNewsTrend"
        >
          <feather-icon icon="PlusIcon" class="text-primary" />
          <span>Edit News Trend</span>
        </b-button>
        <b-button
          variant="outline-primary"
          @click="$router.push('/news-trend')"
        >
          Kembali
        </b-button>
      </b-card-header>
      <b-card-body>
        <div class="mb-3">
          <h4>Judul :</h4>
          <h5>Fasilitas Pelayanan Kesehatan</h5>
        </div>
        <div class="mb-3">
          <h4>Deskripsi :</h4>
          <h5>Fasilitas Pelayanan Kesehatan adalah.......</h5>
        </div>
        <div>
          <h4>Daftar Trend Berita</h4>
        </div>
      </b-card-body>
      <newsTrendTable
        :data="table_data"
        :editData="editDetailNewsTrend"
        :deleteData="deleteNewsTrend"
        :detailData="showDetailNewsTrend"
        :addNews="addNews"
        :useQuery="useQuery"
      />
    </b-card>

    <addNewsFormModal :data="modal_data" />
    <useQueryFormModal :data="query_modal_data" />
    <detailNewsTrendModa :data="detail_modal_data" />
    <editNewsFormModal :data="edit_detail_modal_data" />
    <newsTrendFormModal :data="edit_modal_data" />
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BButton,
} from "bootstrap-vue";
import newsTrendTable from "./newsTrendTable.vue";
import addNewsFormModal from "./addNewsFormModal.vue";
import useQueryFormModal from "./useQueryFormModal.vue";
import detailNewsTrendModa from "./detailNewsTrendModal.vue";
import editNewsFormModal from "./editNewsFormModal.vue";
import newsTrendFormModal from "./newsTrendFormModal.vue";

export default {
  name: "detailNewsTrend",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    newsTrendTable,
    addNewsFormModal,
    useQueryFormModal,
    detailNewsTrendModa,
    editNewsFormModal,
    newsTrendFormModal,
  },

  data: () => ({
    edit_modal_data: {
      title: "Edit News Trend",
      type: "edit",
      id: "edit-newstrend",
    },
    modal_data: {
      title: "Tambah Berita",
      type: "add",
      id: "add-news-newstrend",
    },
    query_modal_data: {
      title: "Set Query News Trend",
      type: "add",
      id: "set-query",
    },
    detail_modal_data: {
      title: "News Trend",
      type: "add",
      id: "show-detail-news-trend",
    },
    edit_detail_modal_data: {
      title: "Edit News Trend",
      type: "add",
      id: "edit-detail-news-trend",
    },
    table_data: {
      filter: null,
      row: 5,
      fields: [
        {
          key: "tanggal",
          label: "Tanggal",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
        },
        {
          key: "jumlah_berita",
          label: "Jumlah Berita",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
          thStyle: { width: "20%", paddingRight: "2rem" },
        },
        {
          key: "streams",
          label: "Streams",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
          thStyle: { width: "20%", paddingRight: "2rem" },
        },
        {
          key: "query",
          label: "Query",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
          thStyle: { width: "20%", paddingRight: "2rem" },
        },
        {
          key: "Action",
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "15%" },
        },
      ],
      items: [
        {
          id: 1,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 2,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 3,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 4,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 5,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 6,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 7,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 8,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 9,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 10,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
        {
          id: 11,
          tanggal: "09-09-2022",
          jumlah_berita: 355,
          streams: 0,
          query: 0,
        },
      ],
    },
  }),

  methods: {
    editNewsTrend(id) {
      this.$bvModal.show("edit-newstrend");
    },
    editDetailNewsTrend(id) {
      this.$bvModal.show("edit-detail-news-trend");
    },
    deleteNewsTrend(id) {
      alert(id);
    },
    showDetailNewsTrend(id) {
      this.$bvModal.show("show-detail-news-trend");
    },
    addNews(id) {
      this.$bvModal.show("add-news-newstrend");
    },
    useQuery(id) {
      this.$bvModal.show("set-query");
    },
  },
};
</script>