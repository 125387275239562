<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <!-- CUSTOM MODAL TITLE -->
    <template #modal-title>
      <h4 class="row" style="margin-left: 1px">
        <b>{{ data.title }}</b>
      </h4>
    </template>
    <validation-observer ref="keywordForm">
      <b-form>
        <!-- TITLE -->
        <h5>Jumlah Berita :</h5>
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Jumlah Berita"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="jumlah_berita"
                type="text"
                placeholder="Masukan Jumlah Berita"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  VBModal,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  name: "newsTrendFormModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    VBModal,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      required,
      jumlah_berita: null,
    };
  },

  methods: {
    validationForm() {
      this.$refs.keywordForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>