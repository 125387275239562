<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    size="lg"
    no-close-on-backdrop
  >
    <!-- CUSTOM MODAL TITLE -->
    <template #modal-title>
      <h4 class="row" style="margin-left: 1px">
        <b>{{ data.title }}</b>
      </h4>
      <small>News Trend pada tanggal 2022-10-03</small>
    </template>
    <div class="mt-2 mb-2 w-50 ml-auto">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" class="text-primary" />
        </b-input-group-prepend>
        <b-form-input type="text" v-model="table_data.filter" placeholder="Masukan Kata Kunci" />
      </b-input-group>
    </div>

    <newsTrendTable :data="table_data" />
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  VBModal,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import newsTrendTable from "./newsTrendTable.vue";

export default {
  name: "newsTrendFormModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    VBModal,
    newsTrendTable,
  },

  props: {
    data: Object,
  },

  data: () => ({
    table_data: {
      filter: null,
      row: 5,
      fields: [
        {
          key: "judul",
          label: "judul",
          thClass: "text-left",
          tdClass: "text-left",
          sortable: true,
        },
        {
          key: "media",
          label: "media",
          thClass: "text-left",
          tdClass: "text-left",
          sortable: true,
          thStyle: { width: "20%", paddingRight: "2rem" },
        },
        {
          key: "tanggal",
          label: "tanggal",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: true,
          thStyle: { width: "20%", paddingRight: "2rem" },
        },
        {
          key: "link",
          label: "link",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "15%" },
        },
      ],
      items: [
        {
          id: 1,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 2,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 3,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 4,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 5,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 6,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 7,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 8,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 9,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 10,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
        {
          id: 11,
          judul: "Soal Gas Air Mata di Tragedi Kanjuruhan, ",
          media: "nasional.tempo.com",
          tanggal: "09-09-2022",
        },
      ],
    },
  }),

  directives: {
    "b-modal": VBModal,
  },
};
</script>